


















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class FortusConfig extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  valid = true
  loadingData = true
  saveIsLoading = false
  rules = {}

  isEnabled = true
  fortusUsername = ''
  fortusPassword = ''
  merchantReference = ''
  paymentTitle = ''

  getInitialData() {
    this.$axios
      .get('/v4/site/integrations/fortus')
      .then((response) => {
        const config = response?.data?.data
        this.isEnabled = !!config._status
        this.fortusUsername = config.fortusUsername
        this.fortusPassword = config.fortusPassword
        this.merchantReference = config.merchantReference
        this.paymentTitle = config.templatePaymentTitle
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  created() {
    this.getInitialData()
    this.rules = {
      fortusUsername: [(v) => !this.isEnabled || !!v || this.$t('c:validation:This field is required')],
      fortusPassword: [(v) => !this.isEnabled || !!v || this.$t('c:validation:This field is required')],
      merchantReference: [(v) => !this.isEnabled || !!v || this.$t('c:validation:This field is required')],
    }
  }

  saveConfig() {
    if (this.$refs.form.validate()) {
      this.saveIsLoading = true
      const data = {
        _status: this.isEnabled,
        fortusUsername: this.fortusUsername,
        fortusPassword: this.fortusPassword,
        merchantReference: this.merchantReference,
        templatePaymentTitle: this.paymentTitle
      }
      this.$axios
        .post('/v4/site/integrations/fortus', data)
        .then(() => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((error) => {
          vxm.alert.error({
            content: String(error?.response?.data?.error?.message || this.$t('Unable to save settings, please contact support')) as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        .finally(() => {
          this.saveIsLoading = false
        })
    }
  } // saveConfig()
}
